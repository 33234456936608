/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {        
        this.cubeportfolio();
        this.contactform();

        function popupwindow(uri, name, w, h) {
          var y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
          var x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

          var win = window.open(uri, name, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x );
          var interval = window.setInterval(function() {
              try {
                  if (win == null || win.closed) {
                    window.clearInterval(interval);
                  }
              }
              catch (e) {
              }
          }, 1000);
          return win;
        }

        $(document).on('click','.popup-share-buttons ul a', function(e){
          if ( ! $(this).closest('li').hasClass('mail-icon') ) {
            e.preventDefault();
            var width = 600;
            var height = 500;
            var type = $(this).attr('data-type');
            if ( type === 'linkedin' ) {
              width = 1000;
              height = 700;
            }
            popupwindow( $(this).attr('href'), $(this).attr('title'), width, height );
          }
        });

        if( window.location.hash ) {
          var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
          if ( hash === 'programare' ) {
            if ( $('#' + hash).length > 0 ) {
              $('html, body').animate({
                scrollTop: $('#' + hash).offset().top - 120
              }, 500);
            }
          }
        }
       
        $(document).on('click', 'a[href*="#"]', function (event) {
          if ( $(this.hash).length > 0 && typeof $(this).attr('data-lity') === 'undefined' ) {
            event.preventDefault();            
            $('html, body').animate({
                scrollTop: $(this.hash).offset().top - 120
            }, 500);
            if ( $('#menu-collapse-main').hasClass('show') ) {
              $('button.navbar-toggler').click();
            }
          }
        });

        var home_slider = $('.hero-banner-slider .slider-content');
        home_slider.owlCarousel({
          center: true,
          items: 1,
          loop: true,
          nav: false,
          dots: true,
          dotsContainer: '.hero-dots-container',
          margin: 0,
          drag: true,
          autoplay: true,
          autoplayHoverPause: true,
          autoplayTimeout: 5000,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
        });

        $.fn.datepicker.dates['ro'] = {
          days: [ "Duminică", "Luni", "Marţi", "Miercuri", "Joi", "Vineri", "Sâmbătă" ],
          daysShort: [ "Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm" ],
          daysMin: [ "Du","Lu","Ma","Mi","Jo","Vi","Sâ" ],
          months: [ "Ianuarie","Februarie","Martie","Aprilie","Mai","Iunie","Iulie","August","Septembrie","Octombrie","Noiembrie","Decembrie" ],
          monthsShort: [ "Ian", "Feb", "Mar", "Apr", "Mai", "Iun","Iul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
          today: "Azi",
          clear: "Șterge",
          format: "dd-mm-yyyy",
          titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
          weekStart: 0
        };

        $('.datepicker').datepicker({
          format: 'dd-mm-yyyy',
          startDate: '+1d',
          autoclose: true,
          weekStart: 1,
          language: 'ro',
          todayHighlight: true,
          orientation: 'bottom',
          maxViewMode: 'days'
        });

        $('.map-section').click(function () {
          $('.map-section').removeClass('no-active').addClass('map-active');
        });

        $( ".map-section" ).mouseleave(function() {
          $('.map-section').addClass('no-active').removeClass('map-active');
        });

        var widget_cazuri_gallery = $('.widget-cazuri-gallery .widget-slider');

        widget_cazuri_gallery.each(function(){
          $(this).owlCarousel({
            center: true,
            items: 2,
            loop: true,
            nav: true,
            dots: true,
            margin: 20,
            drag: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 5000,
            autoWidth:true,
            autoHeight: true,
          });

          $(this).magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
              enabled: true,
              navigateByImgClick: true,
              preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
              tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
              titleSrc: function(item) {
                return '<small>'+$('.footer-bottom-area .cpr').text()+'</small>';
              }
            }
          });
        });

        $(".lista-de-preturi .price-section .price-section-title").stick_in_parent({offset_top: $('header.main-header').height() + 30});
        
        function is_touch_device() {  
          var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
          var mq = function(query) {
            return window.matchMedia(query).matches;
          }

          if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
          }

          // include the 'heartz' as a way to have a non matching MQ to help terminate the join
          // https://git.io/vznFH
          var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
          return mq(query);
        }
        
        if ( !is_touch_device() ) {
          $('.sidebar-sticky .dental-sidebar').stick_in_parent({offset_top: $('header.main-header').height() + 30});
        }

        var content_gallery = $('.content-gallery .owl-carousel');
        content_gallery.each(function(){
          content_gallery.owlCarousel({
            center: true,
            items: 3,
            loop: true,
            nav: true,
            dots: true,
            margin: 30,
            drag: true,
            autoplayHoverPause: true,
            autoWidth:true,
            autoHeight: true,
          });
         
          content_gallery.magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
              enabled: true,
              navigateByImgClick: true,
              preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
              tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
              titleSrc: function(item) {
                return '<small>'+$('.footer-bottom-area .cpr').text()+'</small>';
              }
            }
          });

          $(document).on('click','.owl-item.cloned a',function(e){
            e.preventDefault();
            var target = $(this).attr('href');
            $(this).closest('.owl-carousel').find('.owl-item:not(.cloned)').each(function(){
              $(this).find('a[href="'+target+'"]').click();
            });

          });          
          
        });
        

        

        var cert_slider = $('.certificates .certificates-slider');
        cert_slider.owlCarousel({
          center: true,
          items: 3,
          loop: true,
          nav: false,
          dots: false,
          margin: 30,
          drag: true,
          autoplay: true,
          autoplayHoverPause: true,
          autoplayTimeout: 2500,
        });

        var testimonial_slider = $('.testimonial-comparison .testimonial-slider');
        testimonial_slider.owlCarousel({
          center: true,
          items: 1,
          loop: true,
          nav: false,
          dots: true,
          margin: 30,
          drag: true,
          autoplay: true,
          autoplayHoverPause: true,
          autoplayTimeout: 4500,
        });

        $('.value-box').each(function(){
          var value_slider = $(this).find('.owl-carousel');
          
          value_slider.owlCarousel({
            center: true,
            items: 1,
            loop: true,
            nav: false,
            dots: true,
            margin: 30,
            drag: true,
            onInitialized: function(){
              value_slider.magnificPopup({
                delegate: '.owl-item:not(.cloned) a',
                type: 'image',
                tLoading: 'Loading image #%curr%...',
                gallery: {
                  enabled: true,
                  navigateByImgClick: true,
                  preload: [0,1] // Will preload 0 - before current, and 1 after the current image
                }
              });
            },
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 4500,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
          });


        });

        $('.gallery-list').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
              return '<small>'+$('.footer-bottom-area .cpr').text()+'</small>';
            }
          }
        });

        $('.certificates').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
              return '<small>'+$('.footer-bottom-area .cpr').text()+'</small>';
            }
          }
        });
        AOS.init();

        
        $(".nemes-dental-comparison").each( function() {
          $(this).twentytwenty({
            before_label: 'Înainte', // Set a custom before label
            after_label: 'După' // Set a custom after label
          });
        });

        // $(document).on('click','.scroll-down span',function(e){
        //   e.preventDefault();
        //   var $target = $(this).closest('section').next();
        //   if ( $target.length > 0 ) {
        //     $('html, body').animate({ scrollTop: $target.offset().top - 150 }, 800, 'swing', function() {} );
        //   }
        // });

      },
      contactform: function() {
        /*==================================================================
        [ Validate after type ]*/
        $('.validate-input .input100').each(function(){
          $(this).on('blur', function(){
            if(validate(this) == false){
              showValidate(this);
            }
            else {
              $(this).parent().addClass('true-validate');
            }
          })
        });
      
      
        /*==================================================================
        [ Validate ]*/
        var input = $('.validate-input .input100');

        $('.validate-form').on('submit',function(e){
          var check = true;

          for ( var i=0; i<input.length; i++ ) {
            if( validate(input[i] ) == false ) {
              showValidate(input[i]);
              check=false;
            }
          }

          if ( check === true ) {
            e.preventDefault();
            $theform = $(this);
            $theform.find('button[type="submit"]').button('loading');

            var form_data = $theform.serialize();
            console.log(form_data);
            $.post(
              nd_frontend.ajaxurl,
              {
                action: "nd_contact_form",
                formdata: form_data,
              },
              function(msg)
              {
                obj = JSON.parse( msg );
                // console.log(obj);
                if ( obj.success ) {
                  $theform.find('.form-error-message').slideUp();
                  $theform.find('.form-fields-cnt').slideUp(function(){
                    $theform.find('.thank-you-page-content').slideDown();
                  });
                  $('html, body').animate({ scrollTop:  $theform.offset().top - 100 }, 800, 'swing', function() {} );
                } else {
                  $theform.find('.form-error-message').slideDown();
                  $theform.find('.thank-you-page-content').slideUp();
                }
                $theform.find('button[type="submit"]').button('reset');
              }
            );
            return false;
          }

          return check;
        });


        $('.validate-form .input100').each(function(){
            $(this).focus(function(){
               hideValidate(this);
               $(this).parent().removeClass('true-validate');
            });
        });

         function validate (input) {
            if($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
                if($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                    return false;
                }
            }
            else {
           
              if($(input).val().trim() == ''){
                  return false;
              }
            }                
        }

        function showValidate(input) {
            var thisAlert = $(input).parent();

            $(thisAlert).addClass('alert-validate');

            $(thisAlert).append('<span class="btn-hide-validate">&#xf136;</span>')
            $('.btn-hide-validate').each(function(){
                $(this).on('click',function(){
                   hideValidate(this);
                });
            });
        }

        function hideValidate(input) {
            var thisAlert = $(input).parent();
            $(thisAlert).removeClass('alert-validate');
            $(thisAlert).find('.btn-hide-validate').remove();
        }
      },
      isTouchDevice: function() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
          return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
          return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
      },
      cubeportfolio: function() {
        $('#js-grid-lightbox-gallery').cubeportfolio({
          filters: '#js-filters-lightbox-gallery1, #js-filters-lightbox-gallery2',
          layoutMode: 'grid',
          mediaQueries: [{
              width: 1500,
              cols: 4
          }, {
              width: 1100,
              cols: 3
          }, {
              width: 800,
              cols: 3
          }, {
              width: 480,
              cols: 2
          }, {
              width: 320,
              cols: 1
          }],
          defaultFilter: '*',
          animationType: 'rotateSides',
          gapHorizontal: 10,
          gapVertical: 10,
          gridAdjustment: 'responsive',
          caption: 'zoom',
          displayType: 'sequentially',
          displayTypeSpeed: 100,
          
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
